import React, { useState, useRef } from "react"; 
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import {gbData} from './../../globalVarbs';
import PackageTypeList from './packageTypeList';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Outlet } from "react-router-dom";

export default function PackagePage() {

  console.log(gbData.API_URL);

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('center');
  const [ pckgtypName, setPckgtypName ] = useState(null);
  const [ pckgtypSlug, setPckgtypSlug ] = useState(null);
  const [ packageTypeList, setPackageTypeList ] = useState( [] );
  const [ packageTypeListLoadingStatus, setPackageTypeListLoadingStatus ] = useState( 'Loading List' );

  const fetchData = async () => {
    await fetch( gbData.API_URL+'/admin-get-package-type', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        }
    })
    .then( response => {
        if( !response.ok ){
            throw new Error( 'Network response was not ok' );
        }
        return response.json();
    })
    .then( ApiResponse => {
        // Process the newly created user data
        console.log('ApiResponse:', ApiResponse);
        if( ApiResponse.success == 0 ){
            showT( 'error', 'Failed', ApiResponse.message);
            return;
        }
        
        if( ApiResponse.data.length < 1 ){
            setPackageTypeListLoadingStatus( "No Package Type Found" );
        }else{
            setPackageTypeList( ApiResponse.data );                
        }
        
        
    })
    .catch(error => {
        console.error('Error:', error);
    });   
}

  const toast = useRef(null);

  const showT = ( severity='success', summary='failed', toastMessage='') => {
      toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
  };

  const formik = useFormik({
      initialValues: {
        pckgtypName: '',
        pckgtypSlug:''
      },
      validate: (data) => {
          let errors = {};

          if (!data.pckgtypName) {
              errors.pckgtypName = 'PackageName is required.';
          }

          if (!data.pckgtypSlug) {
              errors.pckgtypSlug = 'PackageSlug is required.';
          }

          return errors;
      },
      onSubmit: (data) => {
          // data && showT(data);
          // console.log(data);
          // return;

          fetch( gbData.API_URL+'/admin-create-package-type', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  "pck_name":data.pckgtypName,
                  "pck_slug":data.pckgtypSlug
              }),
          })
          .then( response => {
              if( !response.ok ){
                  throw new Error( 'Network response was not ok' );
              }
              return response.json();
          })
          .then( ApiResponse => {
              // Process the newly created user data
              console.log('ApiResponse:', ApiResponse);
              if( ApiResponse.success == 0 ){
                  showT( 'error', 'Failed', ApiResponse.message);
                  return;
              }
              // setVisible(false);
              showT( 'success', 'Success', ApiResponse.message);              
              formik.resetForm();
              fetchData();
          })
          .catch(error => {
              console.error('Error:', error);
          });
          // formik.resetForm();
      }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const showD = (position) => {
    setPosition(position);
    setVisible(true);
  };
  
  const headerElement  = (
    <div>
        <h1>Add Package Type</h1>
    </div>
  );

  return (
    <PrimeReactProvider>
        <div className='db-pckg-type-wrapper'>
            <Button label="Show" icon="pi pi-external-link" onClick={() => showD('top')}  />
            <Dialog header={headerElement} visible={visible} style={{ width: '30vw' }} onHide={() => setVisible(false)} position={position}>
                <div className="m-0">
                  <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                    <div className="login-field-wrapper">
                        <div className="login-field">
                            <span className="p-float-labell">
                                <Toast ref={toast} />
                                <InputText
                                    placeholder="PackageType - Name"
                                    name="pckgtypName"
                                    value={formik.values.pckgtypName}
                                    onChange={(e) => {
                                        formik.setFieldValue('pckgtypName', e.target.value);
                                    }}
                                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgtypName')})}
                                /> 
                                {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                {getFormErrorMessage('pckgtypName')}  
                            </span>
                            
                        </div>  
                        <div className="login-field">
                            <span className="p-float-labell">
                                <Toast ref={toast} />
                                <InputText
                                    placeholder="PackageType - Slug"
                                    name="pckgtypSlug"
                                    value={formik.values.pckgtypSlug}
                                    onChange={(e) => {
                                        formik.setFieldValue('pckgtypSlug', e.target.value);
                                    }}
                                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgtypSlug') })}
                                /> 
                                {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                {getFormErrorMessage('pckgtypSlug')}  
                            </span>
                            
                        </div>   
                        <div className="login-field">
                            <Button type="submit" label="Submit" />
                        </div>                
                    </div>            
                  </form>
                </div>
            </Dialog>
            <PackageTypeList fetchData={fetchData} packageTypeList={packageTypeList} packageTypeListLoadingStatus={packageTypeListLoadingStatus}   />
        </div>
    </PrimeReactProvider>
  );
}