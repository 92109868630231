import React, { useState, useRef, useEffect  } from "react"; 
import { Routes, Route, useParams, Navigate, useNavigate   } from 'react-router-dom';

import PackageInformationIncluded from "./packageInformationIncluded"
import PackageInformationTourPlan from "./packageInformationTourPlan"
import PackageListReview from "./packageListReview"

import {gbData} from '../../globalVarbs';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageInformation() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const [dayNight, setDayNight] = useState('');
    const [persons, setPersons] = useState('');
    const [location, setLocation] = useState('');
    const [overview, setOverview] = useState('');

    const toast = useRef(null);

    // const handleNormalFields = (e)=>{
    //     e.preventDefault();
    //     console.log(e.target)
    // }

    const fetchPckgInfor = async ()=>{
        try {
            const response = await fetch(gbData.API_URL+'/admin-get-package-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify({
                    "pckg_list_slug":package_list_slug
                }),
            });

            if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
            }

            const respData = await response.json();
            console.log('API response:', respData);
            formikUpdNF.setFieldValue('dayNightNF', respData.data.information.dayNightNF);
            formikUpdNF.setFieldValue('personsNF', respData.data.information.personsNF);
            formikUpdNF.setFieldValue('locationNF', respData.data.information.locationNF);
            formikUpdNF.setFieldValue('overviewNF', respData.data.information.overviewNF);
            formikUpdNF.setFieldValue('pricetNF', respData.data.information.pricetNF);
            formikUpdNF.setFieldValue('mapLinkNF', respData.data.information.mapLinkNF);
            // Handle successful API response here
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
        //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikUpdNF =  useFormik({
        initialValues: {
            dayNightNF: '',
            personsNF:'',
            locationNF:'',
            overviewNF:'',
            pricetNF:'',
            mapLinkNF:'',
        },
        onSubmit: async (data) => {
            console.log(data);
            let json_inor = JSON.stringify({
                "dayNightNF":data.dayNightNF,
                "personsNF":data.personsNF,
                "locationNF":data.locationNF,
                "overviewNF":data.overviewNF,
                "pricetNF":data.pricetNF,
                "mapLinkNF":data.mapLinkNF
            })
            try {
                const response = await fetch(gbData.API_URL+'/admin-create-package-information', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                  },
                body: JSON.stringify({
                        "pckgLstSlug":package_list_slug,
                        "pckgLstinfo":json_inor,
                        "pckgLstPckgType":package_type
                    }),
                });

                if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
                }

                const data = await response.json();
                console.log('API response:', data);
                showT( 'success', 'Success', data.message);    
                fetchPckgInfor()
                // Handle successful API response here
            } catch (error) {
                console.error('Error:', error);
                // Handle errors here
            } finally {
                // Optionally, clear the form after submission
            //   setFormData([{ id: 1, image: null, text: '' }]);
            }
        }
    })

    const showT = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };

    useEffect(() =>{
        fetchPckgInfor();
    },[])

    return (  

        <PrimeReactProvider>
                <Toast ref={toast} />
            <form onSubmit={formikUpdNF.handleSubmit}>
            <span className="p-float-labell">
                <label>Price</label>
                <InputText name="pricetNF" value={formikUpdNF.values.pricetNF} onChange={(e) => {
                                            formikUpdNF.setFieldValue('pricetNF', e.target.value);
                                        }} />  
            </span><br />
            <span className="p-float-labell">
                <label>Days and Night</label>
                <InputText name="dayNightNF" value={formikUpdNF.values.dayNightNF} onChange={(e) => {
                                            formikUpdNF.setFieldValue('dayNightNF', e.target.value);
                                        }} />  
            </span><br />
            <span className="p-float-labell">
                <label>Number of Persons</label>
                <InputText name="personsNF" value={formikUpdNF.values.personsNF} onChange={(e) => {
                                            formikUpdNF.setFieldValue('personsNF', e.target.value);
                                        }} />  
            </span><br />
            <span className="p-float-labell">
                <label>Location</label>
                <InputText name="locationNF" value={formikUpdNF.values.locationNF} onChange={(e) => {
                                            formikUpdNF.setFieldValue('locationNF', e.target.value);
                                        }} />  
            </span><br />
            <span className="p-float-labell">
                <label>Overview</label>
                <InputTextarea name="overviewNF"  value={formikUpdNF.values.overviewNF} onChange={(e) => {
                                            formikUpdNF.setFieldValue('overviewNF', e.target.value);
                                        }} />   
            </span><br />
            <span className="p-float-labell">
                <label>Map Link</label>
                <InputText name="mapLinkNF" value={formikUpdNF.values.mapLinkNF} onChange={(e) => {
                                            formikUpdNF.setFieldValue('mapLinkNF', e.target.value);
                                        }} />  
            </span><br />
            <Button type="submit" label="update Listing Information" />
            </form>

            <PackageInformationIncluded />
            <PackageInformationTourPlan />
            <PackageListReview />
        </PrimeReactProvider>

    )

}