import { Sidebar } from 'primereact/sidebar';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Outlet, Link } from "react-router-dom";

export default function DashBoardPage() {

  return (
    <PrimeReactProvider>
        <div className='dashboard-container'>
            <div className='dashboard-wrapper'>
                <div className='dashboard-sidebar-menu-wrapper'>
                    <div className='db-sidebar-menu'>
                        <div className='db-sdb-mn-ttl'>
                            <h3>Menus</h3>
                        </div>
                        <div className='db-sdbr-mns'>
                            <ul className='db-sdbr-mns-ul'>
                                <Link to={'/dashboard/packages/list'}>Packages List</Link>
                                <Link to={'/dashboard/packages/category'}>Add Package Category</Link>
                                <Link to={'/dashboard/packages/tags'}>Add Package Tag</Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='dashboard-sidebar-content-wrapper'>
                    <div className='db-content-container'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    </PrimeReactProvider>
  );
}