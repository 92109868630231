import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useParams, Navigate, useNavigate   } from 'react-router-dom';

import {gbData} from '../../globalVarbs';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';



import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageListReview() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const imageRef = useRef();
    const [ listreview, setListreview ] = useState("");
    const [ includeImage, setIncludeImage ] = useState("");
    const [ includeText, setIncludeText ] = useState("");
    const [ rvImageCst, setRvImageCst ] = useState("");

    const [formData, setFormData] = useState({
        image: null,
        text: '',
    });
    
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: name === 'rvImage' ? files[0] : value,
        }));
    };
    

    const toast = useRef(null);
    const showT = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };
    const isFormFieldInvalid = (name) => !!(formikRV.touched[name] && formikRV.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formikRV.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const fetchPckgRV = async ()=>{
        try {
            const response = await fetch(gbData.API_URL+'/admin-get-package-list-review', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify({
                    "pckg_list_slug":package_list_slug
                }),
            });

            if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
            }

            const respData = await response.json();
            console.log('API response:', respData);
            setListreview(respData.data)
            // Handle successful API response here
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
        //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikRV = useFormik({
      initialValues: {
        rvStars:'',
        rvTitle:'',
        rvDetail:'',
        rvSort:'',
        rvImage:''
      },
      validate: (data) => {
          let errorsUpd = {};
          console.log(data);

          if (!data.rvImage) {
            errorsUpd.rvImage = 'Review Image is required.';
            } 

          if (!data.rvStars) {
              errorsUpd.rvStars = 'Review Stars is required.';
          }            

          if (!data.rvTitle) {
            errorsUpd.rvTitle = 'Review Title is required.';
        }    

        if (!data.rvDetail) {
            errorsUpd.rvDetail = 'Review Details is required.';
        }    

        if (!data.rvSort) {
            errorsUpd.rvSort = 'Review Sort is required.';
        }    

         return errorsUpd;
      },
      onSubmit: (data) => {
          // data && showT(data);
          console.log(rvImageCst);
          const formDataToSend = new FormData();
            formDataToSend.append('list_slug', package_list_slug);
            formDataToSend.append('list_packg_type', package_type);
            formDataToSend.append('review_image', rvImageCst);
            formDataToSend.append('review_title', data.rvTitle);
            formDataToSend.append('review_text', data.rvDetail);
            formDataToSend.append('review_star', data.rvStars);
            formDataToSend.append('review_sort', data.rvSort);
            console.log(formDataToSend);
        //   return;

        //   formikRV.resetForm();

          fetch( gbData.API_URL+'/admin-create-package-list-review', {
              method: 'POST',
              body: formDataToSend
          })
          .then( response => {
              if( !response.ok ){
                  throw new Error( 'Network response was not ok' );
              }
              return response.json();
          })
          .then( ApiResponse => {
              // Process the newly created user data
              console.log('ApiResponse:', ApiResponse);
              if( ApiResponse.success == 0 ){
                  showT( 'error', 'Failed', ApiResponse.message);
                  return;
              }
            //   setVisible(false);
            formikRV.resetForm();
            showT( 'success', 'Success', ApiResponse.message);              
              
            fetchPckgRV();
          })
          .catch(error => {
              console.error('Error:', error);
          });
          // formik.resetForm();
      }
    });

      const deleteTPInfor = async ( rowData ) => {
        console.group(rowData);

        const formDataToSend = new FormData();
        formDataToSend.append('id', rowData.id);

        try {
            const response = await fetch(gbData.API_URL+'/admin-delete-package-list-review', {
              method: 'POST',
              body: formDataToSend,
            });
      
            if (!response.ok) {
              throw new Error(`API request failed with status ${response.status}`);
            }
      
            const data = await response.json();
            console.log('API response:', data);
            showT( 'success', 'Success', data.message); 
            fetchPckgRV();
            // Handle successful API response here
          } catch (error) {
            console.error('Error:', error);
            // Handle errors here
          } finally {
            // Optionally, clear the form after submission
            setFormData({ image: '', text: '' });
          }
      }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={()=>deleteTPInfor(rowData)} />
            </React.Fragment>
        );
    };
    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        // switch (field) {
        //     case 'quantity':
        //     case 'price':
        //         if (isPositiveInteger(newValue)) rowData[field] = newValue;
        //         else event.preventDefault();
        //         break;

        //     default:
        //         if (newValue.trim().length > 0) rowData[field] = newValue;
        //         else event.preventDefault();
        //         break;
        // }
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const onRowEditComplete = async (e) => {
        console.log(e)
        const formDataToSend = new FormData();
        formDataToSend.append('sort_for', 'review');
        formDataToSend.append('id', e.newRowData.id);
        formDataToSend.append('review_sort', e.newValue);

        try {
        const response = await fetch(gbData.API_URL+'/admin-package-sort-update', {
            method: 'POST',
            body: formDataToSend,
        });
    
        if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
        }
    
        const data = await response.json();
        console.log('API response:', data);
        showT( 'success', 'Success', data.message); 
        fetchPckgRV();
        // Handle successful API response here
        } catch (error) {
        console.error('Error:', error);
        showT( 'error', 'Error', error); 
        // Handle errors here
        } finally {
        // Optionally, clear the form after submission
        // setFormData({ image: '', text: '' });/
        }
    };

    useEffect(() =>{
        fetchPckgRV();
    },[])

    return (  

        <PrimeReactProvider>
            <Toast ref={toast} />
            <DataTable value={listreview} tableStyle={{ minWidth: '50rem' }}  >
                <Column field="review_image" header="RV Image"></Column>
                <Column field="review_title" header="RN Title"></Column>
                <Column field="review_text" header="RV Review"></Column>
                <Column field="review_star" header="RV Star"></Column>
                <Column key='id' field="review_sort" header="RV Sort" editor={(options) => textEditor(options)} onCellEditComplete={onRowEditComplete}></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>   
            </DataTable>
            <form onSubmit={formikRV.handleSubmit}>
                <span>
                <label htmlFor="image">Review Image: </label>
                <input
                    type="file"
                    id="rv-Image"
                    name="rvImage"
                    value={formikRV.values.rvImage}
                    onChange={(e) => {
                        formikRV.setFieldValue('rvImage', e.target.value);
                        setRvImageCst(e.currentTarget.files[0])
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('rvImage')})}
                    accept="image/jpeg, image/png, image/gif"
                />
                {getFormErrorMessage('rvImage')} 
                </span>
                <span>
                <label htmlFor="image">Review Stars: </label>
                <input
                    type="text"
                    id="rv-Stars"
                    name="rvStars"
                    value={formikRV.values.rvStars}
                    onChange={(e) => {
                        formikRV.setFieldValue('rvStars', e.target.value);
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('rvStars')})}
                />
                {getFormErrorMessage('rvStars')} 
                </span>
                <span>
                <label htmlFor="image">RV Title: </label>
                <input
                    type="text"
                    id="rvTitle"
                    name="rvTitle"
                    value={formikRV.values.rvTitle}
                    onChange={(e) => {
                        formikRV.setFieldValue('rvTitle', e.target.value);
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('rvTitle')})}
                />
                {getFormErrorMessage('rvTitle')} 
                </span>
                <span>
                <label htmlFor="text">RV Detail: </label>
                <textarea
                    id="rvDetail"
                    name="rvDetail"
                    value={formikRV.values.rvDetail}
                    onChange={(e) => {
                        formikRV.setFieldValue('rvDetail', e.target.value);
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('rvDetail')})}
                />
                {getFormErrorMessage('rvDetail')} 
                </span>
                <span>
                <label htmlFor="text">RV Sort: </label>
                <input
                    type="text"
                    id="rvSort"
                    name="rvSort"
                    value={formikRV.values.rvSort}
                    onChange={(e) => {
                        formikRV.setFieldValue('rvSort', e.target.value);
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('rvSort')})}
                />
                {getFormErrorMessage('rvSort')} 
                </span>
                <button type="submit">Submit</button>
            </form>
        </PrimeReactProvider>
    )
}
