import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useParams, Navigate, useNavigate   } from 'react-router-dom';

import {gbData} from '../../globalVarbs';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';



import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageInformationTourPlan() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const imageRef = useRef();
    const [ listTPInfor, setListTPInfor ] = useState("");
    const [ includeImage, setIncludeImage ] = useState("");
    const [ includeText, setIncludeText ] = useState("");

    const [formData, setFormData] = useState({
        image: null,
        text: '',
    });
    
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: name === 'image' ? files[0] : value,
        }));
    };
    

    const toast = useRef(null);
    const showT = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };
    const isFormFieldInvalid = (name) => !!(formikTP.touched[name] && formikTP.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formikTP.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const fetchPckgTPInfor = async ()=>{
        try {
            const response = await fetch(gbData.API_URL+'/admin-get-package-tp-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify({
                    "pckg_list_slug":package_list_slug
                }),
            });

            if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
            }

            const respData = await response.json();
            console.log('API response:', respData);
            setListTPInfor(respData.data)
            // Handle successful API response here
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
        //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikTP = useFormik({
      initialValues: {
        tpTitle: '',
        tpDetail:'',
      },
      validate: (data) => {
          let errorsUpd = {};

          if (!data.tpTitle) {
              errorsUpd.tpTitle = 'Tour Plan Title is required.';
          }

          if (!data.tpDetail) {
              errorsUpd.tpDetail = 'Tour Plan Detail is required.';
          }            

          return errorsUpd;
      },
      onSubmit: (data) => {
          // data && showT(data);
          // console.log(data);
          // return;

          fetch( gbData.API_URL+'/admin-create-package-tp-information', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  "tp_title":data.tpTitle,
                  "tp_detail":data.tpDetail,
                  "pckgListSlug":package_list_slug,
                  "pckgListPckgType":package_type
              }),
          })
          .then( response => {
              if( !response.ok ){
                  throw new Error( 'Network response was not ok' );
              }
              return response.json();
          })
          .then( ApiResponse => {
              // Process the newly created user data
              console.log('ApiResponse:', ApiResponse);
              if( ApiResponse.success == 0 ){
                  showT( 'error', 'Failed', ApiResponse.message);
                  return;
              }
            //   setVisible(false);
            formikTP.resetForm();
            showT( 'success', 'Success', ApiResponse.message);              
              
            fetchPckgTPInfor();
          })
          .catch(error => {
              console.error('Error:', error);
          });
          // formik.resetForm();
      }
    });

      const deleteTPInfor = async ( rowData ) => {
        console.group(rowData);

        const formDataToSend = new FormData();
        formDataToSend.append('id', rowData.id);

        try {
            const response = await fetch(gbData.API_URL+'/admin-delete-package-tp-information', {
              method: 'POST',
              body: formDataToSend,
            });
      
            if (!response.ok) {
              throw new Error(`API request failed with status ${response.status}`);
            }
      
            const data = await response.json();
            console.log('API response:', data);
            showT( 'success', 'Success', data.message); 
            fetchPckgTPInfor();
            // Handle successful API response here
          } catch (error) {
            console.error('Error:', error);
            // Handle errors here
          } finally {
            // Optionally, clear the form after submission
            setFormData({ image: '', text: '' });
          }
      }

      const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={()=>deleteTPInfor(rowData)} />
            </React.Fragment>
        );
    };

    useEffect(() =>{
      fetchPckgTPInfor();
    },[])

    return (  

        <PrimeReactProvider>
            <Toast ref={toast} />
            <DataTable value={listTPInfor} tableStyle={{ minWidth: '50rem' }}>
                <Column field="tp_title" header="TP Title"></Column>
                <Column field="tp_detail" header="TP Detail"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>   
            </DataTable>
            <form onSubmit={formikTP.handleSubmit}>
                <label htmlFor="image">TP Title: </label>
                <input
                    type="text"
                    id="tpTitle"
                    name="tpTitle"
                    value={formikTP.values.tpTitle}
                    onChange={(e) => {
                      formikTP.setFieldValue('tpTitle', e.target.value);
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('tpTitle')})}
                />
                {getFormErrorMessage('tpTitle')} 
                <label htmlFor="text">TP Detail: </label>
                <textarea
                    id="tpDetail"
                    name="tpDetail"
                    value={formikTP.values.tpDetail}
                    onChange={(e) => {
                      formikTP.setFieldValue('tpDetail', e.target.value);
                    }}
                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('tpDetail')})}
                />
                {getFormErrorMessage('tpDetail')} 
                <button type="submit">Submit</button>
            </form>
        </PrimeReactProvider>
    )
}
