import React, { useState, useRef, useEffect  } from "react"; 
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Link } from "react-router-dom";

export default function PackageTypeList( props ) {

    // const isMounted = useRef(false);
    

    const toast = useRef(null);
    const showT = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };
    
    if(props.apckadded == true){
        // alert();
        // fetchData()
    }

    useEffect(() =>{
        props.fetchData();
    },[])

  return (
    
    <PrimeReactProvider>
        { props.packageTypeList.length > 0 ? 
            <div className="flex flex-row flex-wrap">
                {
                    props.packageTypeList.map( ( package_list, index ) =>
                        <Link key={index} to={'/dashboard/package/'+package_list.package_slug}>
                            <div  className="flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2">
                                {package_list.package_name}
                            </div>
                        </Link>
                    )
                }
            </div>
        : <p>{props.packageTypeListLoadingStatus}</p>
        }
        
    </PrimeReactProvider>
    
  );
}