import React, { useState, useRef } from "react"; 
import './custom.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import {gbData} from './globalVarbs';

import "primeflex/primeflex.css";

export default function Login() {
    const [ adminUserName, setAdminUserName ] = useState(null);
    const [ adminPassword, setAdminPassword ] = useState(null);

    const toast = useRef(null);

    const show = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };

    const formik = useFormik({
        initialValues: {
            adminUsername: '',
            adminPassword:''
        },
        validate: (data) => {
            let errors = {};

            if (!data.adminUsername) {
                errors.adminUsername = 'Username is required.';
            }

            if (!data.adminPassword) {
                errors.adminPassword = 'Password is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            // data && show(data);
            // console.log(data);

            fetch(gbData.API_URL+'/api/admin-login', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "uname":data.adminUsername,
                    "password":data.adminPassword
                }),
            })
            .then( response => {
                if( !response.ok ){
                    throw new Error( 'Network response was not ok' );
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                console.log('ApiResponse:', ApiResponse);
                if( ApiResponse.success == 0 ){
                    show( 'error', 'Failed', ApiResponse.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <PrimeReactProvider>

<div className="flex align-items-center justify-content-center">
    <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
            <img src="/demo/images/blocks/logos/hyper.svg" alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">Welcome to RockingHimachalAdventure Admin Panel</div>
            <span className="text-600 font-medium line-height-3">Login to Access your Dashboard</span>
        </div>

        <div>
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                <div className="login-field-wrapper">
                    <div className="login-field">
                        <span className="p-float-labell">
                            <Toast ref={toast} />
                            <InputText
                                placeholder="Admin - Username"
                                name="adminUsername"
                                value={formik.values.adminUsername}
                                onChange={(e) => {
                                    formik.setFieldValue('adminUsername', e.target.value);
                                }}
                                className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('adminUsername')})}
                            /> 
                            {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                            {getFormErrorMessage('adminUsername')}  
                        </span>
                        
                    </div>  
                    <div className="login-field">
                        <span className="p-float-labell">
                            <Toast ref={toast} />
                            <InputText
                                type="password"
                                name="adminPassword"
                                value={formik.values.adminPassword}
                                onChange={(e) => {
                                    formik.setFieldValue('adminPassword', e.target.value);
                                }}
                                className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('adminPassword') })}
                            /> 
                            {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                            {getFormErrorMessage('adminPassword')}  
                        </span>
                        
                    </div>   
                    <div className="login-field">
                        <Button type="submit" label="Submit" />
                    </div>                
                </div>            
            </form>
        </div>
    </div>
</div>


            
        </PrimeReactProvider>
    )
}