import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Login from './Login';
import ErrorPage from './dashboardRoutes/error-page';
// import DashBoardPage from './dashboardRoutes/dashboard';
import Layout from './dashboardRoutes/Layout';
import PackagePage from './dashboardRoutes/packages/packages';
import PackageListPage from './dashboardRoutes/packages/packageList';
import AddPackageCategory from './dashboardRoutes/packages/addPackageCategory';
import AddPackageTag from './dashboardRoutes/packages/addPackageTag';
import PackageInformation from './dashboardRoutes/packages/packageInformation';
import App from './App';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />
  },
  {
    element: <Layout />,    
    children: [      
      {
        path: "/dashboard",
        element: <PackagePage />,
      },
      {
        path: "/dashboard/:package_type/list",
        element: <PackageListPage />,        
      },
      {
        path: "/dashboard/:package_type/category",
        element: <AddPackageCategory />,        
      },
      {
        path: "/dashboard/:package_type/tags",
        element: <AddPackageTag />,        
      },
      {
        path: "/dashboard/:package_type/:package_list_slug",
        element: <PackageInformation />,        
      },
    ],
  }
  // {
  //   path: "/",
  //   element: <Login />,
  //   errorElement: <ErrorPage />,
  //   loader:rootLoader
  // },
  // {
  //   path: "/dashboard",
  //   element: <DashBoardPage />,
  //   errorElement: <ErrorPage />,
  //   children: [    
  //     {
  //       path: "/dashboard/packages",
  //       element: <PackagePage />,        
  //     },  
  //     {
  //       path: "/dashboard/:package_type/category",
  //       element: <AddPackageCategory />,        
  //     },
  //     {
  //       path: "/dashboard/:package_type/tags",
  //       element: <AddPackageTag />,        
  //     },
  //     {
  //       path: "/dashboard/package/:package_slug",
  //       element: <PackageListPage />,        
  //     },
  //   ],
  // },
]);

  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PrimeReactProvider>
    <RouterProvider router={router} />
  </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
