import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useParams, Navigate, useNavigate   } from 'react-router-dom';

import {gbData} from '../../globalVarbs';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageInformationIncluded() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const imageRef = useRef();
    const [ listIncludeInfor, setListIncludeInfor ] = useState("");
    const [ includeImage, setIncludeImage ] = useState("");
    const [ includeText, setIncludeText ] = useState("");

    const [formData, setFormData] = useState({
        image: null,
        text: '',
    });
    
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: name === 'image' ? files[0] : value,
        }));
    };
    

    const toast = useRef(null);
    const showT = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };

    const fetchPckgIncludeInfor = async ()=>{
        try {
            const response = await fetch(gbData.API_URL+'/admin-get-package-include-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify({
                    "pckg_list_slug":package_list_slug
                }),
            });

            if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
            }

            const respData = await response.json();
            console.log('API response:', respData);
            setListIncludeInfor(respData.data)
            // Handle successful API response here
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
        //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formDataToSend = new FormData();
        formDataToSend.append('image', formData.image);
        formDataToSend.append('text', formData.text);
        formDataToSend.append('pckgListSlug', package_list_slug);
        formDataToSend.append('pckgListPckgType', package_type);
    
        try {
          const response = await fetch(gbData.API_URL+'/admin-create-package-include-information', {
            method: 'POST',
            body: formDataToSend,
          });
    
          if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
          }
    
          const data = await response.json();
          console.log('API response:', data);
          showT( 'success', 'Success', data.message); 

          fetchPckgIncludeInfor();
          // Handle successful API response here
        } catch (error) {
          console.error('Error:', error);
          // Handle errors here
        } finally {
          // Optionally, clear the form after submission
          setFormData({ image: '', text: '' });
          imageRef.current.value = "";
        }
      };

      const deleteIncludeInfor = async ( rowData ) => {
        console.group(rowData);

        const formDataToSend = new FormData();
        formDataToSend.append('id', rowData.id);

        try {
            const response = await fetch(gbData.API_URL+'/admin-delete-package-include-information', {
              method: 'POST',
              body: formDataToSend,
            });
      
            if (!response.ok) {
              throw new Error(`API request failed with status ${response.status}`);
            }
      
            const data = await response.json();
            console.log('API response:', data);
            showT( 'success', 'Success', data.message); 
            fetchPckgIncludeInfor();
            // Handle successful API response here
          } catch (error) {
            console.error('Error:', error);
            // Handle errors here
          } finally {
            // Optionally, clear the form after submission
            setFormData({ image: '', text: '' });
          }
      }

      const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={()=>deleteIncludeInfor(rowData)} />
            </React.Fragment>
        );
    };

    useEffect(() =>{
        fetchPckgIncludeInfor();
    },[])

    return (  

        <PrimeReactProvider>
            <Toast ref={toast} />
            <DataTable value={listIncludeInfor} tableStyle={{ minWidth: '50rem' }}>
                <Column field="includeText" header="IncludeText"></Column>
                <Column field="includeImage" header="Icon"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>   
            </DataTable>
            <form onSubmit={handleSubmit}>
                <label htmlFor="image">Image: </label>
                <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/jpeg, image/png, image/gif"
                    ref={imageRef}
                    onChange={handleChange}
                />
                <label htmlFor="text">Text: </label>
                <input
                    type="text"
                    id="text"
                    name="text"
                    value={formData.text}
                    onChange={handleChange}
                />
                <button type="submit">Submit</button>
            </form>
        </PrimeReactProvider>
    )
}
